
.status #header {
   display: none !important;
}

.status.wrapper {
  margin-bottom: 200px;
}
 /** CARDS **/

 .list-group-item {
   margin-left: 10px !important;
 }

 .gray, .unknown {
    display:none !important;
 }
 .green {
   color: #155724 !important;
   background-color: #d4edda !important;
   border-color: #c3e6cb !important;
 }

 .list-group-item.green {
   border-left: 10px solid green !important;
 }

 .list-group-item.orange {
   color: #856404 !important;
   background-color: #fff3cd !important;
   border-left: 10px solid #F0C300 !important;

 }
 .list-group-item.red {
   color: #721c24 !important;
   background-color: #f8d7da !important;
   border-left: 10px solid red !important;
 }

 .list-group-item.unknown {
   color: #818182 !important;
   background-color: #fefefe !important;
   border-left: 10px solid grey !important;
 }

 p.card-text {
   padding-left: 20px;
   padding-top: 10px;
   padding-bottom: 20px;
 }

 h5.card-header small {
   float: right;
   font-size: small;
 }

 .green .card{
   background-color: rgb(130,180,95) !important;
 }

 .red .card {

 }

 .unknown .card {
   background-color: grey !important;
 }

 section.status-info {
   text-align:center !important;
 }

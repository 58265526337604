@import url(https://fonts.googleapis.com/css?family=Lato:300,300i,400);

.status #header {
   display: none !important;
}

.status.wrapper {
  margin-bottom: 200px;
}
 /** CARDS **/

 .list-group-item {
   margin-left: 10px !important;
 }

 .gray, .unknown {
    display:none !important;
 }
 .green {
   color: #155724 !important;
   background-color: #d4edda !important;
   border-color: #c3e6cb !important;
 }

 .list-group-item.green {
   border-left: 10px solid green !important;
 }

 .list-group-item.orange {
   color: #856404 !important;
   background-color: #fff3cd !important;
   border-left: 10px solid #F0C300 !important;

 }
 .list-group-item.red {
   color: #721c24 !important;
   background-color: #f8d7da !important;
   border-left: 10px solid red !important;
 }

 .list-group-item.unknown {
   color: #818182 !important;
   background-color: #fefefe !important;
   border-left: 10px solid grey !important;
 }

 p.card-text {
   padding-left: 20px;
   padding-top: 10px;
   padding-bottom: 20px;
 }

 h5.card-header small {
   float: right;
   font-size: small;
 }

 .green .card{
   background-color: rgb(130,180,95) !important;
 }

 .red .card {

 }

 .unknown .card {
   background-color: grey !important;
 }

 section.status-info {
   text-align:center !important;
 }

:root {
   --webropol-dark-blue: #124456;
   --webropol-blue: #137A98;
   --webropol-dark-green: #537D26;
   --webropol-dark-orange: #C75000;
   --webropol-orange: #FF6429;
   --webropol-danger-red: #DE2A2A;
   --webropol-ui-gray: #555555;
   --webropol-ui-border-gray: #499494;
   --webropol-ui-neutral-gray: #F7F7F7;
   --snippet-bg: #1C1E22;
 }

   body {
     font-family: 'Lato', sans-serif;
     overflow-x: hidden;

   }

   .cookieConsent {
     opacity: 0.8;
   }

   .cookieConsent button {
     opacity: 1.5 !important;
     background-color: #C75000 !important;
     background-color: var(--webropol-dark-orange) !important;
     border-radius: 4px !important;
     color: #fff !important;
   }
   .hidden {
     display:none;
   }


.table-wrapper {
  margin-top: 30px;
  font-size: 85%;
}

.table-wrapper thead {
  text-transform: uppercase;
}

.api-status  {
  padding-left: 15px;
}
 #what, #footer {
   background: #f9f9f9 !important;
 }

 .toaster-wrapper {
   clear:both !important;

 }


 .nav-header a {
   text-transform: uppercase !important;
   font-weight: bolder;
   padding-top: 30px;
 }

 .shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

 .card-btn a {
   display:inline-block;
 }

 .btn-primary {
   background-color: #C75000;
   background-color: var(--webropol-dark-orange);
   border-color: #C75000;
   border-color: var(--webropol-dark-orange);
 }
   /*---------------------------------------
      TYPOGRAPHY
   -----------------------------------------*/

   h1,h2,h3,h4,h5,h6 {
     font-weight: 300;
     line-height: normal;
   }

   h1 {
     font-size: 3em;
   }

   h2 {
     color: #353535;
     font-size: 2em;
   }

   p, ul, li {
     color: #353535;
     font-size: 15px;
     font-weight: normal;
     line-height: 26px;
   }

 label {
   color: #757575;
 }

   /*---------------------------------------
      GENERAL
   -----------------------------------------*/

   html{
     -webkit-font-smoothing: antialiased;
   }

   a {
     color: #C75000;
     color: var(--webropol-dark-orange);
     text-decoration: none !important;
     font-weight:bold;
   }

   a,
   input, button,
   .form-control {
     transition: 0.5s;
   }





   h4 {
     text-transform: uppercase;
     font-size: 20px;
     margin-top: 30px;
   }

footer row {
  min-width: 60vh;
}

a:hover, a:active, a:focus {
  background-color: #FF6429;
  background-color: var(--webropol-orange);
  color: #ffffff;
  outline: none;
}

.left-nav a {
  color: #1C1E22;
  font-weight: normal;
}

.must-read {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold !important;
}

.left-nav a:hover, .left-nav a:active, .left-nav a:focus,
.navbar a:hover, .navbar a:active, .navbar a:focus  {
  background-color: #ffffff;
}
   ::-webkit-scrollbar{
     width: 8px;
     height: 8px;
   }

   ::-webkit-scrollbar-thumb {
     cursor: pointer;
     background: #202020;
   }

   .section-title {
     padding-bottom: 40px;
     float: left;
   }

section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.docs-section {
  padding-top: 5px;
}
.home section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.hljs {
  padding: 30px;
}

   .overlay {
     background: #536976;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
     background: linear-gradient(to right, #292E49, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
     opacity: 0.5;
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 100%;
   }

   @-webkit-keyframes blink {
    from, to { opacity: 1 }
    50% { opacity: 0 }
  }

   @keyframes blink {
    from, to { opacity: 1 }
    50% { opacity: 0 }
  }

  i,  h5 .fas, h5 .far, h5 .fa {
    font-size: 0.85em !important;
    font-weight: 300;
    color: #C75000;
    color: var(--webropol-dark-orange);
    }

.callout-block-success .fas {
  color:inherit !important;
}
   /*---------------------------------------
      BUTTONS
   -----------------------------------------*/

   .section-btn {
     background: #C75000;
     background: var(--webropol-dark-orange);
     border: 0;
     border-radius: 4px;
     color: #ffffff;
     font-size: 16px;
     font-weight: normal;
     padding: 12px 30px;
     transition: 0.5s 0.2s;
   }

   .section-btn:hover,
   .section-btn:focus {
     background: #FF6429;
     background: var(--webropol-orange);
     color: #ffffff;
     cursor: -webkit-grab;
     cursor: grab;
   }

   .card-btn {
     text-align: center;
   }

   .card-btn a {
     display:inline-block;
   }

   /*---------------------------------------
        PRE LOADER
   -----------------------------------------*/

   .preloader {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     z-index: 99999;
     display: flex;
     flex-flow: row nowrap;
     justify-content: center;
     align-items: center;
     background: none repeat scroll 0 0 #ffffff;
   }

   .spinner {
     border: 1px solid transparent;
     border-radius: 3px;
     position: relative;
   }

   .spinner:before {
     content: '';
     box-sizing: border-box;
     position: absolute;
     top: 50%;
     left: 50%;
     width: 45px;
     height: 45px;
     margin-top: -10px;
     margin-left: -10px;
     border-radius: 50%;
     border: 1px solid #575757;
     border-top-color: #ffffff;
     -webkit-animation: spinner .9s linear infinite;
             animation: spinner .9s linear infinite;
   }

   @-webkit-@keyframes spinner {
     to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
   }

   @-webkit-keyframes spinner {
     to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
   }

   @keyframes spinner {
     to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
   }



   /*---------------------------------------
       MENU
   -----------------------------------------*/

   .navbar-nav {
     text-transform:uppercase;
   }

   .navbar-nav li {
     flex-grow: 0.5;
   }

   .top-nav-collapse {
     background: #ffffff;
   }
   .navbar img {
     max-height: 50px;
   }

 .show .navbar-nav {
     text-align:center !important;
   }

   /*---------------------------------------
       HOME
   -----------------------------------------*/

   #home {
     background: url(/static/media/leaf.92fb456c.jpeg) no-repeat center center;
     background-size: cover !important;
     vertical-align: middle;
     display: flex;
     align-items: center;
     min-height: 25vh;
     position: relative;
     padding-top: 12em;
     text-align: center;
     padding:0;
   }

   .docs #home {
    min-height: 30vh;
  }

   #home h1 {
     color: #ffffff;
     margin-top: 10px;
     margin-bottom: 40px;
   }


   .home-info h3 {
     color: #f0f0f0;
     font-size: 11px;
     font-weight: bold;
     letter-spacing: 4px;
     text-transform: uppercase;
     margin: 10px 0;
   }

     /*---------------------------------------
       FEATURE
   -----------------------------------------*/

   #feature {
     background: #ffffff;
   }

   #feature .nav-tabs {
     border-bottom: 0;
   }

   #feature .nav>li>a {
     padding: 8px 0;
   }

   #feature .nav-tabs>li>a {
     color: #999999;
     font-size: 18px;
     border: 0;
     border-bottom: 3px solid transparent;
     border-radius: 0;
     margin-right: 35px;
   }

   #feature .nav-tabs>li.active>a,
   #feature .nav-tabs>li.active>a:focus,
   #feature .nav-tabs>li.active>a:hover {
     background: transparent;
     color: #202020;
   }

   #feature .nav>li>a:focus,
   #feature .nav>li>a:hover {
     background: transparent;
     color: #202020;
   }

   #feature .tab-content {
     margin-top: 50px;
   }

   .tab-pane-item {
     margin: 20px 0;
   }

   .tab-pane-item h2 {
     margin: 0 0 5px 0;
   }

   .feature-image {
     position: absolute;
     bottom: -27em;
   }

 .card-title {
   font-weight:bold;
   font-size: 1.3em;
   text-align: center;
   padding-top: 10px;
 }

 .card-text, .card-text a {
   text-align:center;
 }

   /*---------------------------------------
       WHAT
   -----------------------------------------*/

   #about .section-title,
   #pricing .section-title {
     text-align: center;
   }

   #pricing .col-md-4 {
     margin: 0;
     padding: 0;
   }

   #pricing .col-md-4:last-child .pricing-thumb {
     border-right: 0;
   }

   #what {
     background-color: #ffffff;
   }

   .pricing-thumb {
     background: #ffffff;
     border-right: 2px solid #f0f0f0;
     padding: 20px 40px;
   }

   .pricing-title {
     padding-bottom: 5px;
   }

   .pricing-info {
     border-top: 2px solid #f0f0f0;
     border-bottom: 2px solid #f0f0f0;
   }

   .pricing-info,
   .pricing-bottom {
     padding: 20px 0;
   }

   .pricing-info p {
     font-size: 16px;
   }

   .pricing-bottom {
     position: relative;
   }

   .pricing-bottom span {
     font-size: 20px;
   }

   .pricing-btn {
     top: 15px;
     background-color: #C75000 !important;
     background-color: var(--webropol-dark-orange) !important;
     color: #ffffff;
   }

   .card {
     padding:20px;
   }

 .post-code {
   text-align: center;
 }


#changelog {
  font-size: inherit;
}



   /*---------------------------------------
       CONTACT
   -----------------------------------------*/

 .contact-row,
 iframe {
   display:flex;
   width: 100%;
 }

 iframe {
   min-height: 700px;
   border:none;
 }
 #contact .section-title {
   padding-bottom: 10px;
 }

 #contact .form-wrapper {
   text-align: left;
 }
   /*---------------------------------------
      FOOTER
   -----------------------------------------*/
#footer {
  padding-top: 30px;
  padding-bottom: 30px;
}

#footer img {
  max-height: 100px;
}
section.footer {
  padding-bottom: 0;
}

.callout-block {
  padding: 1.5rem;
  border-left: 3px solid #5d6778;
  border-left-color: rgb(93, 103, 120);	background: #fefefe;
  margin-top: 1rem;
  margin-bottom: 1rem;

 }


  .callout-title {
    font-size: 1rem;
  }

 .callout-block-info {

    border-color: #5b99ea;
    background: #f9fbfe;
  }

  .callout-block.callout-block-info .callout-title {
    color: #1c6fdc;
 }

      .callout-block-info 		a {
        color: #1c6fdc;
    }

    .callout-block.callout-block-success {
      border-color: #5cb377;
      background: #e7f4eb;
  }
  .callout-block.callout-block-success .callout-title {
    color: #3e8554;
 }
      .callout-block-success		a {
        color: #3e8554;		}


   .callout-block-warning {
    border-color: var(--webropol-warning);
    background: lighten(var(--webropol-warning), 35%);
         }
         .callout-block-warning		.callout-title {
      color: darken(var(--webropol-warning), 15%);
    }
      .callout-block-warning		a {
      color: darken(var(--webropol-warning), 15%);
    }

   .callout-block-danger {
    border-color: var(--webropol-danger);
    background: lighten(var(--webropol-danger), 35%);
         }
         .callout-block-danger		.callout-title {
      color: darken(var(--webropol-danger), 15%);
    }
      .callout-block-danger		a {
      color: darken(var(--webropol-danger), 15%);
    }
   /*---------------------------------------
      RESPONSIVE STYLES
   -----------------------------------------*/

   @media only screen and (max-width: 1200px) {

     .custom-navbar .navbar-nav {
       margin-left: 3em;
     }

     .home-info {
       margin-top: 0;
     }
   }


   @media only screen and (max-width: 992px) {


     .custom-navbar .navbar-nav {
       margin-left: 0;
     }

     .custom-navbar .nav li a {
       font-size: 14px;
       padding-right: 15px;
       padding-left: 15px;
     }

     #feature .nav-tabs>li>a {
       font-size: 16px;
       margin-right: 20px;
     }

     .feature-image {
       bottom: -24em;
     }

     .pricing-thumb {
       margin-bottom: 30px;
     }


     #footer {
      display:none !important;
    }
   }


   @media screen and (max-width: 767px) {

     .section-title {
       padding-bottom: 20px;
     }

     .custom-navbar {
       background: #ffffff;
       box-shadow: 0 1px 30px rgba(0, 0, 0, 0.1);
       padding: 10px 0;
       text-align: center;
     }

     .custom-navbar .nav li a {
       line-height: normal;
       padding: 5px;
     }

     .custom-navbar .navbar-brand,
     .top-nav-collapse .navbar-brand {
       color: #202020;
       font-weight: normal;
     }

     .custom-navbar .nav li a,
     .top-nav-collapse .nav li a {
       color: #656565;
     }

     .feature-image {
       position: relative;
       bottom: 0;
     }

     #about .col-md-4:nth-child(3n) .team-thumb {
       display: flex;
       flex-direction: column-reverse;
     }

     .team-thumb-down:after {
       top: -15px;
       bottom: inherit;
       border-top: inherit;
       border-bottom: 15px solid #ffffff;
     }

     .testimonial-image,
     .testimonial-info {
       height: 85vh;
     }

     .testimonial-info {
       padding: 30px;
     }

   }

   @media screen and (max-width: 570px) {

   }

   @media screen and (max-width: 480px) {

     .online-form input[type="email"],
     .online-form .form-control {
       width: 85%;
     }

     .online-form .form-control {
       display: block;
       margin: 20px auto;
     }

     .online-form button {
       position: relative;
       right: 0;
     }

     .testimonial-image,
     .testimonial-info {
       height: 65vh;
     }
   }




   footer.status {
    bottom:0;
    width:100%;
    z-index: inherit;
 }
